@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: 'Work Sans';
}


@font-face {
  font-family: 'Work Sans';
  src: url('../public/fonts/Work_Sans/light.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
    font-family: 'Work Sans';
    src: url("../public/fonts/Work_Sans/regular.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Work Sans';
    src: url("../public/fonts/Work_Sans/medium.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Work Sans';
    src: url("../public/fonts/Work_Sans/bold.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
  }

